div.activity-container {
  background-color: var(--courses-purple);
  color: var(--dark-purple);
  display: flex;
  padding: 0px 60px 0px 60px;
  justify-content: space-between;
  height: 100%;
  min-height: 100vh;
  padding-top: 30px;
}

/* .title {
  margin: 30px 0px;
} */

div.info-wrapper {
  width: 60%;
}

div.activity-header {
  padding: 20px 25px;
  font-size: 20px;
  line-height: 20px;
  border-radius: 12px;
  margin-bottom: 50px;
}

div.activity-header p {
  padding: 0px;
  margin: 0px;
}

div.info-container {
  display: flex;
  flex-direction: column;
}

/* div.info-container > * {
  background-color: white;
  padding: 5px 0px;
} */

.large-paragraph {
  background-color: white;
  padding: 20px;
}

div.student-submissions {
  /* background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  */
  max-width: 40%;
  min-width: 30%;
  /* height: fit-content;
  padding: 40px;  */
}

div.student-submissions-content {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* max-width: 40%;
  min-width: 30%; */
  height: fit-content;
  padding: 40px;
}

.students-list {
  margin-top: 32px;
}

.students-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0;
}

.product-circle {
  margin-right: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.product-circle.pending {
  background-color: #f26e6e;
}

.product-circle.submitted {
  background-color: rgb(201, 255, 167);
}

.students-list-item p {
  margin: 0px;
  padding: 10px 0px;
  display: flex;
  align-items: center;
}

.buttons-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.button-main {
  box-shadow: none;
  font-size: 16px;
  padding: 6px 12px;
  line-height: 1.5;
}
