.submission-form-container {
  padding: 30px;
  min-height: 100%;
}

.submission-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.submission-title {
  font-size: 1.2em;
}

.submission-content-wrapper {
  padding: 0px 20px;
}