.courses-show-container {
  padding: 30px;
  min-height: 100%;
}

.utis-list-container {
  display: flex;
  padding: 50px;
  justify-content: space-around;
}

.utis-list {
  display: flex;
  flex-direction: column;
}

.utis-list-content {
  margin-top: 30px;
}

.utis-item {
  display: flex;
  align-items: center;
}

.utis-item-card {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 10px 0px;
}

.utis-item-card.dragged {
  width: 120%;
  filter: drop-shadow(-10px 10px 10px rgba(57, 52, 135, 0.25));
}

.utis-item-card-disabled {
  opacity: 0.5;
}

.utis-item-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 30px;
  margin: 0px;
  background-color: white;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.utis-item-header.dragged {
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.utis-item-index {
  font-size: 2.3em;
  margin: 0px;
  padding: 0px;
  padding-right: 20px;
  font-weight: 700;
}

.utis-item h2 {
  font-size: 1.5em;
}

.utis-item-product {
  display: flex;
  justify-content: center;
  background-color: var(--dark-purple);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.utis-item-product.dragged {
  visibility: hidden;
}

.utis-item-product p {
  color: white;
  padding: 15px;
  font-size: 1.4em;
  margin: 0px;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 500;
}

.course-icon {
  height: 25px;
  cursor: pointer;
}

.course-icon.dragged {
  visibility: hidden;
}

.student-course-grade-content {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}

.course-grade-percentage {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}
