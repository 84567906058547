.events-container {
  padding: 30px;
  background-color: var(--events-purple);
  display: flex;
  justify-content: space-around;
  min-height: 100%;
}

.events-list-container {
  width: 600px;
}

.events-tabs-container {
  display: flex;
  align-items: center;
  text-align: center;
  list-style-type: none;
}

.events-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  padding: 15px 30px;
  width: 200px;
  height: 90px;
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  cursor: pointer;
}

.active-events-tab {
  /* text-decoration: underline; */
  border-bottom: 3px solid white;
  background-color: rgba(40, 36, 96, 0.2);
}

.events-list {
  list-style: none;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: 10px;
  /* justify-content: center; */
  height: 500px;
  overflow-y: scroll;
}

.event-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  background-color: var(--event-list-item-color);
  color: white;
  align-items: center;
  width: 100%;
}

.event-item-selected {
  background-color: var(--dark-blue);
}

.event-item:hover {
  background-color: var(--event-list-item-color-hover);
}

.event-item-content {
  display: flex;
  flex-direction: column;
}

.event-item-content p {
  margin: 3px;
}

.event-card {
  margin-top: 30px;
  height: 600px;
  width: 610px;
  padding: 40px;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.events-show {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.events-show h2 {
  font-size: 2.8em;
  text-align: center;
  
}

.events-show .event-name {
  font-size: 2.1em;
  text-align: center;
  margin: 15px 0px;
}

.events-show .event-text{
 font-size: 1.7em; 
 margin: 10px 0px;
}