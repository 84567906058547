.course-card-2 {
    padding: 12px;
    height: 176px;
}

.course-card-2-title {
    padding: 12px;
    height: 66px;
    color: white;
    border-radius: 15px;
    text-align: center;
    margin-bottom: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.course-card-2 p {
    margin: 0px;
}

.course-card-2 img.icon {
    height: 14px;
}