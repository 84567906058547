.utis-show-container {
  padding: 30px;
  background-color: var(--courses-purple);
  display: flex;
  justify-content: center;
  min-height: 100%;
}

.activity-list-container {
  /* width: 700px;
   */
  width: 100%;
}

.activity-list-container-content {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.activity-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.activity-list {
  width: 680px;
}

.activity-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activity-card {
  box-sizing: border-box;
  border-radius: 6px;
  border-left: 20px solid var(--gph-purple);
  /* height: 115px;   */
  background-color: white;
  margin: 20px 0px;
  padding: 20px 20px;
  width: 600px;
}

.activity-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.course-card-wrapper .card {
  margin-bottom: 20px;
}

.grade-icon {
  height: 20px;
  margin-right: 6px;
}

.activity-list-item-product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 3px 20px rgba(132, 149, 188, 0.29);
  border-radius: 5px;
  padding-right: 20px;
}
