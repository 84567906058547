.signin-wrapper {
    background-image: url("/assets/school_theme_pattern.png");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.signin-card {
    width: 1100px;
    height: 700px;
    display: flex;
    justify-content: center;
    border-radius: 12px;
}

.signin-image-wrapper {
    background-image: url("/assets/students.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 100%;
    width: 40%;
    border-radius: 12px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.signin-image-layer {
    background-color: var(--dark-purple);
    position: absolute;
    opacity: 0.7;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.signin-content {
    width: 60%;
    margin: auto;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.signin-logo {
    height: 200px;
}