/* Styles for the toggle button */

.toggle-container {
  display: inline-block;
  position: relative;
  width: 200px; /* Adjust the width as needed */
  height: 40px; /* Adjust the height as needed */
  background-color: rgba(125, 121, 175, 0.3);; /* Border color for the whole toggle button */
  border-radius: 20px;
  cursor: pointer;
  overflow: hidden;
}

.toggle-switch {
  display: flex;
  height: 100%;
  width: 50%;
}

.toggle-slider {
  width: 150px; /* Half the width of the container */
  height: 100%;
  background-color: #282460; /* Slider color */
  border-radius: 20px;
  transition: transform 0.3s ease;
}

.toggle-container.reportes .toggle-slider {
  transform: translateX(100%);
}

.toggle-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px; /* Adjust the font size as needed */
  color: #fff; /* Text color for the unselected option */
}

.toggle-label-left {
  left: 17%;
}

.toggle-label-right {
  right: 15%;
}

.toggle-container.reportes .toggle-label-left,
.toggle-container.dashboard .toggle-label-right {
  color: var(--subtle-text); /* Text color for the selected option */
}
