.courses-form-container {
  display: flex;
  justify-content: center;
  padding: 30px;
  height: 100%;
}

.form-container {
  width: 90%;
  display: flex;
  height: 550px;
}

.courses-details {
  width: 70%;
}

.courses-details-container {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 30px;
}

.courses-students-container {
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.courses-students-container a {
  font-size: 1em;
  padding: 10px;
  margin: 10px;
  border: 2px solid var(--dark-purple);
  border-radius: 4px;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  color: var(--dark-purple);
}

.courses-students-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.courses-details-container label {
  color: var(--dark-purple) !important;
}

.student-list {
  margin: 20px 0px;
  height: 300px;
  width: 100%;
  overflow-y: scroll;
}

.student-list li {
  list-style-type: none;
  padding: 5px;
}

.student-list li:nth-child(odd) {
  background-color: #f2f3f6;
}

.student-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.student-list-item div {
  display: flex;
  align-items: center;
}

.student-list-item p {
  margin: 0px;
  padding: 0px 10px;
}

.student-list-item .icon {
  height: 21px;
  width: 21px;
  margin-right: 20px;
}

.student-list-item .trash-icon {
  height: 20px;
  width: 16px;
  margin-right: 20px;
}

.student-list-item img {
  height: inherit;
  width: inherit;
  cursor: pointer;
}

.courses-details-options {
  background-color: #f6f8fb;
  width: 30%;
  padding-top: 60px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 550px;
}

.side-tabs p {
  padding: 15px 25px;
  font-size: 1.1em;
  margin: 0px;
  font-weight: 700;
  /* cursor: pointer; */
  border-left: 10px solid rgba(0, 0, 0, 0);
  text-transform: uppercase;
}

.side-tabs p.active-tab {
  background-color: #e1e9f5;
  border-left: 10px solid #2a5894;
}

.courses-details-button-group {
  width: auto;
  padding: 0px 20px;
  display: flex;
  justify-content: space-around;
}

.courses-details-button-group > * {
  width: 90px !important;
}

.primary-btn {
  margin: 0px;
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 9px;
  border-width: 0px;
  background-color: var(--dark-purple);
  cursor: pointer;
  font-weight: 700;
  font-size: 0.9em;
}
