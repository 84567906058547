.product-card {
  padding: 30px;
  /* background-color: white;
  border-radius: 5px;
  margin-bottom: 50px; */
  margin-bottom: 50px;
}

.step-box {
  display: flex;
  flex-direction: row;
  border-radius: 4px 0px 0px 4px;
  margin: 10px 0px;
  /* background-color: var(--courses-purple); */
  /* height: 100px; */
}

.step-number {
  background-color: var(--gph-purple);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  padding: 20px;
  border-radius: 4px 0px 0px 4px;
  margin: 0px;
}

.step-instruction {
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  margin: 0px;
}

.product-skill {
  padding: 10px 15px;
  margin: 10px 0px;
  width: 100%;
}

.product-skill p {
  margin: 5px 0px;
}

.product-skill-ser {
  border-left: 15px solid var(--ser);
}

.product-skill-conocer {
  border-left: 15px solid var(--conocer);
}

.product-skill-hacer {
  border-left: 15px solid var(--hacer);
}

.product-skill-convivir {
  border-left: 15px solid var(--convivir);
}
