.primary-nav {
  display: flex;
  justify-content: space-between;
}

.primary-nav .logo {
  margin: 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primary-nav img {
  height: 50px;
}

.primary-nav a {
  margin: 25px;
  font-weight: 700;
}

.active-link {
  text-decoration: underline;
}

.primary-nav .logout-btn {
  width: 250px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.secondary-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  height: 120px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.secondary-nav > * {
  position: relative;
  z-index: 2;
}

.secondary-nav:before {
  content: "";
  position: absolute;
  width: 300%;
  height: 300%;
  top: -100%;
  left: -100%;
  z-index: 0;
  background-image: url("/assets/school_theme_pattern.png");
  background-repeat: repeat;
  background-size: 500px;
  opacity: 1;
  /* -webkit-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
  transform: rotate(-30deg); */
}

.nav-header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 310px;
}

.nav-header a {
  text-decoration: underline;
}

.name-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}