.file-link-container {
    margin-bottom: 40px;
    width: 100%;
}

.link-item {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  box-shadow: 0px 3px 20px rgba(132, 149, 188, 0.29);
  border-radius: 5px;
  background-color: white;
  padding-right: 20px;
  margin-bottom: 15px;
}

.link-item-content {
  width: 100%;
  display: flex;
  margin-left: 15px;
  justify-content: space-between;
}

.link-text {
  /* width: 400px; */
  margin: 0px;
  display: flex;
  align-items: center;
}
