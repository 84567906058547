@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html,
body,
#root,
.App {
  height: 100vh;
  min-height: 100%;
}

* {
  box-sizing: border-box;
}

:root {
  --dark-purple: #282460;
  --bg-purple: #393487;
  --students-purple: #8da5de;
  --events-purple: #8495bc;
  --general-events: #60e5bd;
  --faculty-events: #72dbff;
  --administration-events: #b9a9f2;
  --event-list-item-color: #7289be;
  --event-list-item-color-hover: #6076aa;
  --courses-purple: #dde7ff;
  --text-color-claro: #383784;
  --gph-purple: #5d7fcd;
  --dark-blue: #2a5894;

  --cultural: #9b7ec0;
  --ocupacional: #5f83b2;
  --cientifico: #83bc09;
  --humanistico: #75cf94;
  --comunitaria: #c5b7da;
  --electivo: #649117;
  /* buttons */
  --button-green: #c9ffa7;
  --button-red: #f26e6e;

  --ser: #4ad23b;
  --conocer: #ffd966;
  --hacer: #dc66a1;
  --convivir: #bf2bc9;

  --subtle-text: #7D79AF;

  --success: #8AB828;
  --danger: #D04C4C;

  --global-bg: #F9FAFE;
}

body {
  margin: 0;
  /* Body */
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'DM Sans', source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  height: 100%;
  min-height: 100vh;
}

.main-wrapper {
  min-height: 100%;
  width: 100%;
  background-color: var(--global-bg);
}

.content {
  max-width: 1100px;
  margin: 0px auto;
}

a {
  text-decoration: none;
}

.green {
  background-color: var(--button-green);
}

.red {
  background-color: var(--button-red);
}

.dark-purple {
  background-color: var(--dark-purple);
}

.dark-purple-text {
  color: var(--dark-purple) !important;
}

.bg-purple {
  background-color: var(--bg-purple) !important;
}

.courses-purple {
  background-color: #dde7ff;
}

.humanistico {
  background-color: var(--humanistico);
}

.cientifico {
  background-color: var(--cientifico);
}

.ocupacional {
  background-color: var(--ocupacional);
}

.cultural {
  background-color: var(--cultural);
}

.comunitaria {
  background-color: var(--comunitaria);
}

.electivo {
  background-color: var(--electivo);
}

.light-purple {
  background-color: #7289be;
}

.very-light-purple {
  background-color: #c8d3ee;
}

.light-gray {
  background-color: #f2f3f6;
}

.white {
  background-color: white;
}

.white-text {
  color: white !important;
}

.dark-blue {
  background-color: var(--dark-blue);
}

.name-circle {
  background-color: white;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name-circle.medium {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 10px 20px;
  font-size: 0.7em;
}

.name-circle.small {
  height: 30px;
  width: 30px;
  font-size: 0.5em;
}

.name-circle.huge {
  height: 150px;
  width: 150px;
  font-size: 2.5em;
  background-color: lightblue;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  line-height: normal;
}

p {
  line-height: normal;
}

label {
  font-weight: 700 !important;
}

.MuiFormControlLabel-root {
  color: var(--dark-purple);
}

hr {
  border-color: #2a5894;
}

.MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
  color: white !important;
  font-weight: 700;
}

.invert > .MuiOutlinedInput-notchedOutline {
  border-color: #282460 !important;
}

.invert > .MuiSelect-outlined.MuiSelect-outlined {
  color: #282460 !important;
  font-weight: 700;
}

.MuiInputBase-root.Mui-disabled {
  opacity: 0.4;
}

.MuiPaper-rounded {
  border-radius: 20px !important;
}

/* events purple scrollable */
.events-scrollable::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.events-scrollable::-webkit-scrollbar-track {
  background-color: #2a5894;
  border-radius: 10px;
  border: 3px solid var(--events-purple);
}

/* Handle */
.events-scrollable::-webkit-scrollbar-thumb {
  background: #2a5894;
  border-radius: 10px;
}

/* Handle on hover */
.events-scrollable::-webkit-scrollbar-thumb:hover {
  background: #557fb6;
}

/* scrollable on white background */

.scrollable::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.scrollable::-webkit-scrollbar-track {
  background-color: #2a5894;
  border-radius: 10px;
  border: 3px solid #fff;
}

/* Handle */
.scrollable::-webkit-scrollbar-thumb {
  background: #2a5894;
  border-radius: 10px;
}

/* Handle on hover */
.scrollable::-webkit-scrollbar-thumb:hover {
  background: #557fb6;
}

.search-form {
  height: 40px;
  background: white;
  box-sizing: border-box;
  border-radius: 25px;
  border: 2px solid #282460;
  padding: 0px;
  display: flex;
  justify-content: space-between;
}

.search {
  width: 80%;
  height: 34.5px;
  line-height: 30px;
  outline: 0;
  border: 0;
  font-size: 1em;
  border-radius: 20px;
  padding: 5px 0px;
  padding-left: 20px;
}

.search-image {
  padding: 10px;
  height: 38px;
  width: 40px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
}

.modal {
  display: flex;
  width: 500px;
  flex-direction: column;
}

.text-align-center {
  text-align: center;
}

ul {
  margin: 0px;
  padding: 0px;
}

.textfield-input fieldset.MuiOutlinedInput-notchedOutline {
  border-color: var(--dark-purple) !important;
}

.textfield-outlined {
  margin: 8px !important;
  min-width: 170px !important;
  width: 100%;
}

.textfield-input {
  display: flex;
  align-items: center;
}

.MuiOutlinedInput-multiline {
  width: 100%;
}

.MuiOutlinedInput-input {
  color: var(--dark-purple) !important;
}

.MuiFormLabel-root.Mui-error {
  color: #f44336 !important;
}

.select-input {
  /* width: 100%; */
  display: flex;
  align-items: center;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: var(--dark-purple);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  padding-bottom: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -15px;
  left: 125%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent var(--dark-purple) transparent transparent;
}

/* tooltip left */

.tooltip .tooltiptext-left {
  visibility: hidden;
  width: 120px;
  background-color: var(--dark-purple);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  padding-bottom: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -15px;
  right: 130%;
}

.tooltip:hover .tooltiptext-left{
  visibility: visible;
}

.tooltip .tooltiptext-left::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent var(--dark-purple) transparent transparent;
}

.primary-btn {
  margin: 0px;
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 9px;
  border-width: 0px;
  background-color: var(--dark-purple);
  cursor: pointer;
  font-weight: 700;
  font-size: 0.9em;
}

.primary-btn.disabled {
  opacity: 0.5;
}

.primary-btn.disabled:hover {
  cursor: not-allowed !important;
}

.primary-btn-outline {
  font-size: 1em;
  padding: 10px;
  margin: 10px;
  border: 2px solid var(--dark-purple);
  border-radius: 4px;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  color: white;
  font-weight: 700;
}

.secondary-btn-outline {
  font-size: 1em;
  padding: 10px;
  margin: 10px;
  border: 2px solid white;
  border-radius: 4px;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  color: white;
}

.secondary-btn-outline {
  font-size: 1em;
  padding: 10px;
  margin: 10px;
  border: 2px solid white;
  border-radius: 4px;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  color: white;
}

.secondary-btn-outline:hover {
  background-color: white;
  color: var(--dark-purple);
}

.tertiary-btn {
  margin: 0px;
  width: 160px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 9px;
  border-width: 0px;
  background-color: var(--gph-purple);
  cursor: pointer;
  font-weight: 700;
  font-size: 0.9em;
}

.tertiary-btn.disabled {
  cursor: not-allowed;
}

.tertiary-btn-outline {
  font-size: 1em;
  padding: 10px;
  margin: 10px;
  border: 2px solid var(--gph-purple);
  border-radius: 4px;
  text-decoration: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  color: var(--gph-purple);
  display: flex;
  justify-content: center;
}

.tertiary-btn-outline:hover {
  background-color: var(--gph-purple);
  color: white;
}

.tertiary-btn-outline.disabled {
  border: 2px solid var(--dark-purple);
  color: var(--dark-purple);
}

.tertiary-btn-outline.disabled:hover {
  cursor: not-allowed;
  color: var(--dark-purple);
  border: 2px solid var(--dark-purple);
  background-color: white;
}

.rounded {
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  font-size: 1.5em !important;
  font-weight: 700 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.event-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 10px 20px;
}

.event-circle-general {
  background-color: var(--general-events);
}

.event-circle-faculty {
  background-color: var(--faculty-events);
}

.event-circle-administration {
  background-color: var(--administration-events);
}

.card {
  background-color: white;
  padding: 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card h3 {
  margin-bottom: 20px;
}

.no-margin {
  margin: 0px !important;
}

.no-margin .MuiOutlinedInput-root {
  border-radius: 0px 4px 4px 0px;
}

.hidden {
  visibility: hidden;
}

.pointer {
  cursor: pointer;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.swal2-styled.swal2-confirm {
  background-color: var(--dark-purple) !important;
}

.file-icon img {
  background-color: var(--gph-purple);
  padding: 10px;
  height: 49px;
  display: flex;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.sub-header-text {
  font-size: 1.25em;
  font-weight: bold;
}

.header-ser {
  background-color: var(--ser);
  color: white !important;
}

.header-conocer {
  background-color: var(--conocer);
  color: white !important;
}

.header-hacer {
  background-color: var(--hacer);
  color: white !important;
}

.header-convivir {
  background-color: var(--convivir);
  color: white !important;
}

.disabled {
  opacity: 0.3;
}

.breadcrumb-link {
  text-decoration: underline;
}

.breadcrumb-current {
  text-decoration: none;
  font-weight: bold;
}

.big-numbers-text {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Text/Dark */
  color: var(--dark-purple-text);
}

.page-title {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  display: flex;
  align-items: center;
  /* Text/Dark */
  color: var(--dark-purple-text);
}

.medium-title {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  /* Text/Dark */
  color: var(--dark-purple-text);
}

.subtle-text {
  color: var(--subtle-text);
}

.progress-bar {
  display: flex;
}

.progress-bar-success {
  background-color: var(--success);
  height: 6px;
  border-top-left-radius: 200px;
  border-bottom-left-radius: 200px;
}

.progress-bar-danger {
  background-color: var(--danger);
  height: 6px;
  border-top-right-radius: 200px;
  border-bottom-right-radius: 200px;
}

.product-submitted-percentage-subcard {
  padding: 20px 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.float-left {
  margin-left: auto !important;
}